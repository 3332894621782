import React from "react"
import PropTypes from "prop-types"

import Image from "../../image/Image"
import LayoutEN from "./LayoutEN"

import { comparerOrdreImages } from "../../../js/client-es/utils"

export default function LayoutGalerieListeTirages(props) {
  const page = props.page
  const images = props.images.sort(comparerOrdreImages)

  return (
    <LayoutEN page={page}>
      <section id="galerie">
        {images.map(i => {
          return (
            <div
              id={i.code}
              className="galerie-tirage-conteneur-image "
              key={i.code}
            >
              <Image
                image={i}
                afficherDescription={false}
                classeCSSLegende="galerie-tirage-legende-image"
                langue={page.langue}
                afficherBlocAchatTirage={true}
              />
            </div>
          )
        })}
      </section>
    </LayoutEN>
  )
}

LayoutGalerieListeTirages.propTypes = {
  page: PropTypes.object,
  images: PropTypes.array.isRequired,
}
